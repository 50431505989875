@import '~@/assets/scss/main.scss';

.content-card {
    @include material-shadow($z-depth: 3, $color: $soft-shadow);
    border-radius: $border-radius-card;
    background-color: white;
    margin-bottom: 1rem;

    .content-card-border-top {
        height: 1rem;
        width: 100%;
        border-radius: $border-radius-card $border-radius-card 0rem 0rem;
        background: linear-gradient(to right, $blue, darken($blue, 10%));
    }

    .content-card-content {
        padding: 1rem;
    }
}
